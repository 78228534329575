<template>
    <div class="row p-2">
        <div class="col">
            <div class="row">

                <div class="col-12 mb-3">
                    <label class="form-label">Título do Módulo</label>
                    <input type="text" v-model="copy.title" class="form-control"
                           placeholder="Título do Módulo" required>
                </div>

                <div class="col-12 mb-3">
                    <div class="d-flex justify-content-between">
                        <label class="form-label justify-content-between d-flex">
                                <span>
                                    Descrição
                                </span>
                        </label>
                        <div class="d-flex">
                            <button class="btn btn-outline-info btn-sm mb-1" id="buttonGenerateModule"
                                    type="button"
                                    :class="{disabled: disabled}"
                                    @click="generateDescriptionModule">
                                Gerar automaticamente
                            </button>
                            <i v-b-tooltip.hover.right="'Gerar a descrição da mentoria via inteligência artificial de acordo com o título e o resumo.'"
                               class="fa fa-info-circle ml-2 my-auto"></i>
                        </div>
                    </div>
                    <textarea class="form-control" placeholder="Insira uma descrição do módulo..."
                              rows="5" v-model="copy.description"></textarea>
                </div>

                <div class="col-md-6 mb-3">
                    <label class="form-label d-flex">Prazo Mentorado
                        <i v-b-tooltip.hover.right="'Prazo em dias para término da módulo'"
                           class="fa fa-info-circle ml-2 my-auto"></i>
                    </label>
                    <input type="number" v-model="copy.deadline"
                           class="form-control" placeholder="Prazo em dias" required>
                </div>

                <div class="col-md-6 mb-3">
                    <label class="form-label d-flex">Prazo Mentor
                        <i v-b-tooltip.hover.right="'Prazo em dias para retorno do mentor a contar a partir da finalização do mentorado'"
                           class="fa fa-info-circle ml-2 my-auto"></i>
                    </label>
                    <input type="number" v-model="copy.return_period"
                           class="form-control" placeholder="Prazo em dias" required>
                </div>

                <div class="col-12 mb-3">
                    <label class="form-label">Imagem do Módulo</label>
                    <input type="file" class="form-control" @change="handleImageChange"
                           placeholder="Imagem do Módulo" accept=".jpg, .jpeg, .png, .webp" required :id="`img${copy.id}`">
                </div>

                <div class="col-12">
                    <div class="alert alert-warning">
                        Dimensões indicadas: 320 x 480px
                    </div>
                </div>

                <div class="col-12" v-if="show_alert">
                    <div class="alert alert-danger">
                        As dimensões da sua imagem são menores que o recomendado, o que pode resultar em
                        distorções ou perda de qualidade ao ser exibida.
                    </div>
                </div>

                <div class="col-12 mt-auto mb-3">
                    <div class="custom-control custom-checkbox">
                        <input :id="'checkShow' + copy.id" type="checkbox"
                               class="custom-control-input"
                               v-model="copy.show_title">
                        <label :for="'checkShow' + copy.id"
                               class="custom-control-label">
                            Exibir título do módulo
                        </label>
                        <i v-b-tooltip.hover.right="'Se este campo estiver selecionado o título do Módulo será exibido em cima da imagem'"
                           class="fa fa-info-circle ml-2"></i>
                    </div>
                </div>

                <div class="col-12 mt-auto mb-3" v-if="copy.show_title">
                    <div class="custom-control custom-checkbox">
                        <input :id="'checkColor' + copy.id" type="checkbox"
                               class="custom-control-input"
                               v-model="copy.mentoring_color">
                        <label :for="'checkColor' + copy.id"
                               class="custom-control-label">
                            Título na cor da Mentoria
                        </label>
                        <i v-b-tooltip.hover.right="'Se este campo estiver selecionado o título do Módulo a ser exibido em cima da imagem ficará com a cor selecionada para a mentoria'"
                           class="fa fa-info-circle ml-2"></i>
                    </div>
                </div>

                <div class="col-12 mt-auto mb-3">
                    <div class="custom-control custom-checkbox">
                        <input :id="'checkAuth' + copy.id" type="checkbox"
                               class="custom-control-input"
                               v-model="copy.authorized">
                        <label :for="'checkAuth' + copy.id"
                               class="custom-control-label">
                            Liberado
                        </label>
                        <i v-b-tooltip.hover.right="'Se este campo estiver selecionado o aluno terá acesso ao módulos e seus conteúdos a partir da sua data de inscrição a mentoria.'"
                           class="fa fa-info-circle ml-2"></i>
                    </div>
                </div>

            </div>
        </div>

        <div v-if="show" style="width: 320px!important;">
            <Cropper
                :img="copy.img"
                :proportion="0.66"
                @set-img="setImg"
                @reset-logo="resetLogo"
                ref="cropper"
            />
        </div>

        <div class="position-relative text-center" style="width: 320px" v-else>
            <img v-if="!copy.imgUrl" src="@/assets/images/modulo.png" alt="generica_modulo"
                 class="img-fluid img-module">
            <img v-else :src="copy.imgUrl" alt="generica_modulo" class="img-fluid img-module">
            <span v-if="copy.show_title" :style="
                                    `
                                     position: absolute;
                                     top: 400px;
                                     z-index: 1000;
                                     left: 20px;
                                     right: 20px;
                                     font-size: 20px;
                                     font-weight: bold;
                                     max-width: 280px;
                                     color: ${copy.mentoring_color ? color : '#FFF'}
                                    `">
                                {{ copy.title }}
                            </span>
        </div>

        <div class="col-12 text-end mt-3" v-if="!show">
            <button type="button" class="btn btn-outline-danger" @click="cancel">Cancelar</button>
            <button type="button" class="btn btn-outline-success ml-2" @click="save">
                Confirmar
            </button>
        </div>
    </div>
</template>

<script>


import {defineComponent} from "vue";
import Cropper from "@/components/base/cropper.vue";
import http from "@/http";

export default defineComponent({
    components: {
        Cropper
    },

    props: {
        module: {
            required: true,
            type: Object
        }
    },

    data() {
        return {
            copy: {},
            visible: true,
            loaded: false,
            color: null,
            show: false,
            show_copy: false,
            show_content: false,
            show_alert: false,
            disabled: false
        }
    },

    methods: {
        generateDescriptionModule() {
            if (!this.disabled) {
                this.disabled = true;
                const content = this.copy.title;
                this.$store.commit('api/setSpinner', true);
                http.post('mentorings/generate-description-module', {content})
                    .then(response => {
                        this.copy.description = response.data[0];
                    })
                    .catch(e => {
                        console.error('Erro na requisição GPT:', e);
                    })
                    .finally(() => {
                        this.$store.commit('api/setSpinner', false);
                        this.disabled = false;
                    })
            }
        },

        async getData() {
            this.copy = JSON.parse(JSON.stringify(this.module));
            delete this.copy.contents;
        },

        setImg(blob) {
            this.copy.imgUrl = URL.createObjectURL(blob);
            this.copy.img = new File([blob], "cropped-image.png", {type: 'image/png'});
            this.show = false;
        },

        resetLogo() {
            document.getElementById('img' + this.copy.id).value = '';
            this.show_alert = false;
            this.show = false;
        },

        handleImageChange(event) {
            const input = event.target;
            if (input.files && input.files[0]) {
                const reader = new FileReader();

                reader.onload = (e) => {
                    this.copy.img = e.target.result;
                    this.show = true;
                };
                const maxWidth = 320;
                const maxHeight = 480;
                if (input.width < maxWidth || input.height < maxHeight) this.show_alert = true
                reader.readAsDataURL(input.files[0]);
            } else {
                this.copy.img = '';
                this.copy.imgUrl = null;
                this.show = false;
                this.show_alert = false;
            }
        },

        toggleVisibility() {
            this.visible = !this.visible;
        },

        cancel() {
            this.$emit('cancel');
        },

        save() {
            this.$emit('update-module', this.copy);
        },

    },

    mounted() {
        this.getData();
    }

})
</script>
